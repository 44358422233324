import React, { Component } from 'react';

 const EchartsReact = Loader.loadBaseComponent('EchartsReact')
class ResourceTendencyStatic extends Component {
    getOtionTem() {
        let {dataByDaysList} = this.props;
        let totalRecource=dataByDaysList.length>0?dataByDaysList:[0,0,0,0,0,0,0];
        let maxNum=Math.max(...totalRecource)
		let base=maxNum>10000?10000:(maxNum>1000?1000:1)
        let xAxisName=maxNum>10000?'万':(maxNum>1000?'千':'')
        totalRecource = totalRecource.map(v => v/base)
        //近一周的日期数组
        const weekDays = [Utils.getDay(-6),Utils.getDay(-5),Utils.getDay(-4),Utils.getDay(-3),Utils.getDay(-2),Utils.getDay(-1),Utils.getDay(0)]
        
        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter:(t,h) => {
                    return t[0].name + ': ' + t[0].value + xAxisName
                },
                confine: true
            },
            grid:{
                x:63,
                y:10,
                x2:30,
                y2:20,
                borderWidth:0
            },
             xAxis: {
                type: 'value',
                name: xAxisName,
				minInterval: base<1000?1:'',
				nameLocation :'end',
                boundaryGap: [0, 0.01],
                axisTick:false,
                axisLine:{
                    show:false,
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: '#333333'
                    }
                } ,
                splitLine:{
                    lineStyle: {
                        color: ['#D8DCE3']
                    }
                }
            },
            yAxis: {
                type: 'category',
                data: weekDays,
                axisTick:false,
                axisLine:{
                    show:false,
                },
                axisLabel: {
                    interval:0,
                    show: true,
                    textStyle: {
                        color: '#333333'
                    }
                }
            },
            series: [
                {
                    barWidth: 4,
                    barGap: 0.8,
                    name: '每日人脸抓拍数',
                    type: 'bar',
                    label: {
                        emphasis: {
                            show: true,
                            position: 'right',
                            textStyle: {
                                fontSize: '12',
                                color: ' #8899BB'
                            }
                        }
                    },
                    data: totalRecource,
                    itemStyle:{
                        normal:{color:' #8899BB'},
                    },
                }
            ]
        };

        return option;
    }
    render() {
        let {cardLength} = this.props
        return (
            <div className='chartAnother'>
                <EchartsReact option={this.getOtionTem()} style={{height:'calc(100% - 32px)'}}/>
            </div>
        )
    }
}
export default ResourceTendencyStatic;
import React from "react";

const IconFont = Loader.loadBaseComponent("IconFont");
function sumArr(arr) {
  let a = 0;
  for (let i = 0; i < arr.length; i++) {
    a += parseInt(arr[i], 10) ? parseInt(arr[i], 10) : 0;
  }
  return a;
}
export default class CameraShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraList: []
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      cameraList: nextProps.CameraList
    });
  }
  componentDidMount() {
    const { CameraList } = this.props;
    this.setState({
      cameraList: CameraList
    });
  }
  render() {
    const { cameraList } = this.state;
    const b =
      Array.isArray(cameraList) &&
      cameraList.filter(v => v.deviceType == 100604).map(v => v.count);
    const c = sumArr(b); //智能枪机
    const e =
      Array.isArray(cameraList) &&
      cameraList
        .filter(v => v.deviceType == 100603 || v.deviceType == 100607)
        .map(v => v.count);
    const f = sumArr(e); //抓拍机
    const h =
      Array.isArray(cameraList) &&
      cameraList
        .filter(v => v.deviceType == 103501 || v.deviceType == 103502)
        .map(v => v.count);
    const i = sumArr(h); //门禁
    const k =
      Array.isArray(cameraList) &&
      cameraList.filter(v => v.deviceType == 103407).map(v => v.count);
    const l = sumArr(k); //闸机
    const n =
      Array.isArray(cameraList) &&
      cameraList.filter(v => v.deviceType == 100602).map(v => v.count);
    const o = sumArr(n); //球机
    let camArr = [
      { count: c, title: "智能枪机", icon: "icon-S_Point_CameraSmart" },
      { count: o, title: "球机", icon: "icon-S_Point_CameraBall" },
      { count: f, title: "抓拍机", icon: "icon-S_Point_CameraGun" },
      { count: i, title: "门禁", icon: "icon-M_AID_Access" },
      { count: l, title: "闸机", icon: "icon-S_Point_Car" }
    ];
    return (
      <React.Fragment>
        {camArr.map(v => (
          <div className="content_people">
            <div className="content_people_left">
              <IconFont type={v.icon} theme="outlined" />
              <span className="left_title">{v.title}</span>
            </div>
            <div className="content_people_right">{v.count ? v.count : 0}</div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

import React from 'react';
import AlarmState from './components/ResourceChart/index';
import * as _ from 'lodash';

import './index.less';

import PersonnelClassify from './components/PersonnelClassify/index';
import VariousDeviceCount from './components/VariousDeviceCount/index';

const IconFont = Loader.loadBaseComponent('IconFont');
const ImageView = Loader.loadBaseComponent('ImageView');

function sumArr(arr) {
  let a = 0;
  for (let i = 0; i < arr.length; i++) {
    a += parseInt(arr[i], 10) ? parseInt(arr[i], 10) : 0;
  }
  return a;
}
class VillageMesDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showface: false,
      showcam: false,
      LongLiveTotal: 0,
      ReUnAppearTotal: 0,
      dataByDaysList: [],
      CameraList: []
    };
  }
  /**控制是否显示社区下拉详细信息 */
  getPersonCount = id => {
    let { show } = this.state;
    this.setState({
      show: !show
    });
  };
  getFaceCapture = id => {
    let { showface } = this.state;
    this.setState({
      showface: !showface
    });
  };
  getCameraClassify = id => {
    this.setState({
      showcam: !this.state.showcam
    });
  };
  handleOnSelect = id => {
    this.props.handleVillageSelect(id);
  };

  render() {
    const { villageName, address, id, pictureUrl } = this.props.data || {};
    const { show, showcam, showface } = this.state;
    let { choseId, type, otherData = [] } = this.props;
    let dataByDaysList =
      otherData[0] && Array.isArray(otherData[0].trend) && otherData[0].trend.length
        ? otherData[0].trend.map(v => parseInt(v.count, 10))
        : [0, 0, 0, 0, 0, 0, 0];
    let judge = choseId == id;
    let countData = otherData[0] ? otherData[0].deviceTypeCount : [];
    let countDevice = otherData[0] && sumArr(otherData[0].deviceTypeCount.map(v => parseInt(v.count)));
    return (
      <div className={`left-community ${judge ? 'back' : ''}`}>
        <div className="left-community-top" onClick={this.handleOnSelect.bind(this, id)}>
          <div className="community-img">
            {pictureUrl ? (
              <ImageView hasErrorImageStyle={false} src={pictureUrl} />
            ) : (
              <IconFont style={{ fontSize: '80px', color: '#D8DCE3' }} type={'icon-M_AID_Community'} theme="outlined" />
            )}
          </div>
          <div className="flex-community" title={villageName.length > 7 ? villageName : ''}>
            <span>{villageName.length > 7 ? villageName.substring(0, 7) + '...' : villageName}</span>
            <div className="community-word" title={address.length > 9 ? address : ''}>
              {address.length > 9 ? address.substring(0, 9) + '...' : address}
            </div>
          </div>
        </div>
        <div className="card-mas-community">
          <div className={`card-box ${show ? 'person-show' : ''}`}>
            <div className="card-one" onClick={this.getPersonCount.bind(this, id)}>
              <span>
                <IconFont type={'icon-S_Bar_NameCard'} theme="outlined" />
                <span>
                  {type == 'unregistered' ? '未登记' : '已登记'}
                  人员
                </span>
              </span>
              <span>人</span>
              <span className="font-resource-normal">
                {type == 'unregistered'
                  ? otherData[0]
                    ? parseFloat(otherData[0] ? otherData[0].frequentlyCount * 1 + otherData[0].occasionallyCount * 1 : 0).toLocaleString()
                    : '/'
                  : otherData[0]
                  ? parseFloat(otherData[0] ? otherData[0].permanentCount * 1 + otherData[0].disappearCount * 1 : 0).toLocaleString()
                  : '/'}
              </span>
              <span className="plus-community">
                <IconFont type={show ? 'icon-S_Arrow_SmallUp' : 'icon-S_Arrow_SmallDown'} theme="outlined" />
              </span>
            </div>
            <div className={`community-count person-count ${show ? 'show' : ''} `}>
              <PersonnelClassify
                LongLiveTotal={type == 'unregistered' ? (otherData[0] ? otherData[0].frequentlyCount : 0) : otherData[0] ? otherData[0].permanentCount : 0}
                ReUnAppearTotal={type == 'unregistered' ? (otherData[0] ? otherData[0].occasionallyCount : 0) : otherData[0] ? otherData[0].disappearCount : 0}
                type={type}
              />
            </div>
          </div>
          <div className={`card-box ${showcam ? 'carmon-show' : ''}`}>
            <div className="card-one two" onClick={this.getCameraClassify.bind(this, id)}>
              <span>
                <IconFont type={'icon-S_Point_Camera'} theme="outlined" />
                <span>小区实有设备</span>
              </span>
              <span>台</span>
              <span className="font-resource-normal">{countDevice ? countDevice : 0}</span>
              <span className="plus-community">
                <IconFont type={showcam ? 'icon-S_Arrow_SmallUp' : 'icon-S_Arrow_SmallDown'} theme="outlined" />
              </span>
            </div>
            <div className={`community-content carmon-count ${showcam ? 'showcam' : ''}`}>
              <VariousDeviceCount CameraList={countData} />
            </div>
          </div>
          <div className={`card-box ${showface ? 'number-show' : ''}`}>
            <div className="card-one three" onClick={this.getFaceCapture.bind(this, id)}>
              <span>
                <IconFont type={'icon-S_AID_Smile'} theme="outlined" />
                <span>近七天人脸抓拍数</span>
              </span>
              <span>张</span>
              <span className="font-resource-normal">
                {type == 'registered'
                  ? otherData[0]
                    ? parseFloat(otherData[0].total ? otherData[0].total : 0).toLocaleString()
                    : '/'
                  : otherData[0]
                  ? parseFloat(otherData[0].total ? otherData[0].total : 0).toLocaleString()
                  : '/'}
              </span>
              <span className="plus-community">
                <IconFont type={showface ? 'icon-S_Arrow_SmallUp' : 'icon-S_Arrow_SmallDown'} theme="outlined" />
              </span>
            </div>
            <div className={`community-face-count  ${showface ? 'showface' : ''}`}>
              <AlarmState dataByDaysList={dataByDaysList} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default VillageMesDetail;
